.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slide-track {
  animation: scroll 20s infinite linear;
  display: flex;
  width: calc(250px * 14);
}

.slide {
  margin: 0 40px;
  height: 100px;
  width: 250px;
  justify-content: center;
}

.slide img {
  width: auto;
  height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
