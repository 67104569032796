.spikes {
  position: relative;
}

.spikes::after {
  content: "";
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 50px;
  background-size: 50px 100%;
  background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%),
    linear-gradient(225deg, #ffffff 25%, transparent 25%);
  background-position: 0 0;
}

.wave {
  position: absolute;
  height: 70px;
  width: 100%;
  background: #2c3e50;
  bottom: 0;
}

.wave::before,
.wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
}

.wave::before {
  width: 55%;
  height: 109%;
  background-color: #fff;
  right: -1.5%;
  top: 60%;
}
.wave::after {
  width: 55%;
  height: 100%;
  background-color: #2c3e50;
  left: -1.5%;
  top: 40%;
}
