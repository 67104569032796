.preloader {
  height: 100vh;
  width: 100%;
  background-color: #242424;
  color: #9b844c;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preloader .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  font-size: 20px;
  font-weight: 800;
  overflow: hidden;
  color: #9b844c;
}
