.box-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 50px;
}
.box {
  padding: 5%;
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  h3 {
    text-align: center;
    font-size: 20px;
  }
}
.box img {
  padding: 1rem;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.5s;
}
.overlay {
  color: #000;
  width: 100%;
  height: 0;
  background: #9b844c;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.5s;
}
.overlay h3 {
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5%;
  font-family: "Bebas Neue", sans-serif;
  font-size: 30px;
  letter-spacing: 2px;
}
.overlay a {
  margin-top: 5px;
  color: #262626;
  text-decoration: none;
  font-size: 14px;
  background: #fff;
  border-radius: 50px;
  text-align: center;
  padding: 5px 15px;
  transition: ease-in-out 0.2s;
}

.overlay a:hover {
  color: #fff;
  background: #000;
}
.box:hover img {
  transform: scale(1.1);
}
.box:hover .overlay {
  height: 100%;
}
